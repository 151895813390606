class ZIDXAccountLeadRouteEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLeadRouteEditContainer";
    }
    getMatchURL(){
        return "/account/lead-routes/edit";
    }
    render(){
        // console.log("edit lead route");
        let userSearchInput=ZIDX.$('.zidxAccountLeadRouteEditContainer #lead_routing_assign_to_user_id_input');
        if(userSearchInput.length>0) {
            window.ZIDXOptions.loadOnceFunctions.push(function () {
                ZIDX.$('.zidxAccountLeadRouteEditContainer #lead_routing_assign_to_user_id').filterByText(userSearchInput, true);
            });
        }

        ZIDX.$("#lead_routing_type_id").on("change", function(this:HTMLInputElement){
            ZIDX.$("#lead_routing_assign_to_email_fieldset").hide();
            ZIDX.$("#lead_routing_assign_to_user_fieldset").hide();
            ZIDX.$("#lead_routing_assign_to_round_robin_fieldset").hide();
            switch(this.value){
                case "1":
                    break;
                case "2":
                    ZIDX.$("#lead_routing_assign_to_user_fieldset").show();
                    break;
                case "3":
                    ZIDX.$("#lead_routing_assign_to_email_fieldset").show();
                    break;
                case "4":
                    ZIDX.$("#lead_routing_assign_to_round_robin_fieldset").show();
                    break;
            }
        });
        ZIDX.$("#lead_routing_type_id").trigger("change");
    }
}